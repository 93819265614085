<template>
  <div id="contact-form-wrapper">
    <ContactForm
      v-if="!formSent"
      :translation="translations[currentLocale]"
      :validation="validation"
      :lang="currentLocale"
      @submit-form="submitForm"
    />

    <ContactFormThank
      v-if="formSent"
      :translation="translations[currentLocale]"
      :has-error="hasError"
    />
  </div>
</template>

<script setup lang="ts">
import { defineAsyncComponent, nextTick, ref } from 'vue'

import { useRuntimeConfig } from '#app'
import { useCurrentLocale, useRouter } from '#imports'

import formHandler from '~/helpers/odooFormHandler'
import { scrollToElement } from '~ui/helpers/scrollToElement'

import translations from './translations.json'
import validation from './validation.json'

const currentLocale = useCurrentLocale<typeof translations>()

const ContactForm = defineAsyncComponent(
  () => import('~ui/components/Base/Forms/ContactForm/ContactForm')
)
const ContactFormThank = defineAsyncComponent(
  () => import('~ui/components/Base/Forms/ContactForm/ContactFormThank')
)

const config = useRuntimeConfig()
const router = useRouter()

const formSent = ref(false)
const hasError = ref(false)

const submitForm = async (rawFormData: any) => {
  const formData = new FormData()

  const name = `${rawFormData.firstName} ${rawFormData.lastName ?? ''}`.trim()

  const isFile = Array.isArray(rawFormData.file) && !!rawFormData.file.length

  formData.append('name', name)
  formData.append('contact_name', name)
  formData.append('email_from', rawFormData.email)
  formData.append('description', rawFormData.message)
  formData.append('filename', isFile ? rawFormData.file[0].file : undefined)
  formData.append('rodo_check', `${rawFormData.requiredConsent ? 1 : 0}`)

  const apiResponse = await formHandler(formData, config.public)

  hasError.value = !apiResponse
  formSent.value = true

  nextTick(() => {
    scrollToElement('#contact-form-wrapper', 320)
  })

  if (formSent.value) {
    // Add ID needed to get form submit status for gtm
    router.push(`#form-${hasError.value ? 'error' : 'success'}`)
  }
}
</script>
