import type { RuntimeConfig } from 'nuxt/schema'
import { withBase } from 'ufo'

const odooFormHandler = async (
  data: FormData,
  publicConfig: RuntimeConfig['public'],
  options?: any
) => {
  const url = withBase(
    publicConfig.ODOO_API_CONTACT_FORM_ENDPOINT,
    publicConfig.ODOO_API_BASE_URL
  )

  try {
    const res = await fetch(url, {
      method: 'POST',
      body: data,
      ...options
    })

    if (!res.ok)
      throw new Error(`Odoo api request error! Status: ${res.status}`)

    const result = await res.json()

    return 'id' in result
  } catch (err) {
    console.error(err)
    return false
  }
}

export default odooFormHandler
